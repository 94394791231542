import * as React from 'react'
import injectSheet from 'react-jss'
import { Element } from 'react-scroll'
import { graphql, StaticQuery } from 'gatsby'
// import {VelocityTransitionGroup} from 'velocity-react'
import Stroke from '../../components/Page/Stroke'
import Section from '../../components/Section/Section'
import SplitContent from '../../components/SplitContent/SplitContent'
import SmallTestimonial from '../../components/SocialProof/smallTestimonial'

interface DiscoverProps {
  classes?: any
}

interface DiscoverEntity {
  datoCmsHomePage: {
    discover: {
      id: string
      title: string
      mainTitle: string
      dark: boolean
      reverse: boolean
      videoLink: string
      testimonial: any
      textNode: any
    }[]
  }
}

class Discover extends React.Component<DiscoverProps, {}> {
  render() {
    const { classes: c } = this.props

    return (
      <StaticQuery
        query={query}
        render={({ datoCmsHomePage: { discover } }: DiscoverEntity) => {
          return (
            <Element name="discover" className={c.discover}>
              <Section>
                {discover.map(
                  ({
                    id,
                    title,
                    mainTitle,
                    dark,
                    reverse,
                    videoLink,
                    testimonial: { avatar, dark: testimonialDark, quote, name, sub },
                    textNode: {
                      childMarkdownRemark: { html },
                    },
                  }) => (
                    <Stroke title={mainTitle} key={id}>
                      <SplitContent
                        textTitle={title}
                        textParagraphs={
                          <div className={c.text}>
                            <div dangerouslySetInnerHTML={{ __html: html }}></div>
                            <SmallTestimonial
                              name={name}
                              sub={sub}
                              quote={quote}
                              avatar={avatar}
                              dark={testimonialDark}
                            />
                          </div>
                        }
                        video={videoLink}
                        reverse={reverse}
                      />
                    </Stroke>
                  ),
                )}
              </Section>
            </Element>
          )
        }}
      />
    )
  }
}

const query = graphql`
  query HomepageDiscover {
    datoCmsHomePage {
      discover {
        id
        title
        mainTitle
        videoLink
        dark
        reverse
        textNode {
          childMarkdownRemark {
            html
          }
        }
        testimonial {
          avatar
          dark
          quote
          name
          sub
        }
      }
    }
  }
`

const styles = {
  discover: {
    width: '100%'
  },
  text: {
    margin: '48px 0',
    fontSize: '1.3rem',
    lineHeight: '1.5',
    fontWeight: '300',
    '& > div': {
      '& > p': {
        color: '#999',
        '& > strong': {
          color: '#409FBD',
        },
      },
    },
  },
}

export default injectSheet(styles)(Discover)
